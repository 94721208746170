import { makeTranslation } from "../lib/translation"

export const translations = makeTranslation({
  navigation: {
    home: {
      en: "Home",
      sr: "Naslovna",
    },
    aboutUs: {
      en: "About us",
      sr: "O nama",
    },
    gallery: {
      en: "Gallery",
      sr: "Galerija",
    },
    assortment: {
      en: "Assortment",
      sr: "Naš asortiman",
    },
    contact: {
      en: "Contact",
      sr: "Kontakt",
    },
    shop: {
      en: "Shop",
      sr: "Prodavnica",
    },
  },
  home: {
    title: {
      en: "Welcome to <br/> <b>Evrotom!</b>",
      sr: "Dobrodošli u <br/> <b>Evrotom!</b>",
    },
    leftButton: {
      en: "About us",
      sr: "O nama",
    },
    rightButton: {
      en: "Shop",
      sr: "Prodavnica",
    },
  },
  aboutus: {
    title: {
      en: "",
      sr: "VIŠE OD TRI DECENIJE ISKUSTVA",
    },
    about: {
      en: "",
      sr: `<b>EVROTOM</b> je porodična kompanija koja se bavi proizvodnjom pčelarske oprema, lekova i hrane za pčele od 1991. godine. Deda Toma, zaljubljenik u pčele, započeo je proizvodnju hrane i lekova za pčele pre 30 godina. Prvo za sebe i svoje pčele, a kada se uverio u kvalitet svojih proizvoda i za druge pčelare i njihove pčele. Kako se posao širio, povećali su se i naši kapaciteti. U želji da upotpunimo naš asortiman, sa svim potrebnim alatima za pčelare, početkom 2000. godina započeli smo proizvodnju pčelarske opreme. Sada naša firma broji 35 zaposlenih, na 3 lokacije u Rumi. <b>EVROTOM</b> proizvodi su dostupni u većini država u Evropi, kao i u državama Bliskog Istoka, uz stalno otvaranje novih tržišta. Svakodnevnim radom i trudom težimo da proširimo naš asortiman i kapacitete.`,
    },
    missionTitle: {
      en: "",
      sr: "Misija",
    },
    visionTitle: {
      en: "",
      sr: "Vizija",
    },
    mission: {
      en: "",
      sr:
        "Misija Očuvati pčelarstvo širokom ponudom kvalitetnih proizvoda uz povoljne cene. Svakodnevnim radom unaprediti naše proizvode u praktičnije, ekonomičnije i ekološki prihvatljivije. Pružiti našim radnicima odlične uslove za rad, dok razvijamo infrastrukturu, kako na domaćem tako i stranom tržištu.",
    },
    vision: {
      en: "",
      sr: `Vizija Naša vizija je da <b>EVROTOM</b> bude jedan od sinonima za pčelarstvo.Da postanemo broj jedan proizvođač lekova i hrane za pčele i opreme za pčelarstvo sa Balkana.`,
    },
    skills: {
      equipment: {
        en: "",
        sr: "OPREMA ZA PČELARSTVO",
      },
      tools: {
        en: "",
        sr: "PRIBOR ZA PČELARSTVO",
      },
      medicine: {
        en: "",
        sr: "LEKOVI ZA PČELE",
      },
      food: {
        en: "",
        sr: "HRANA I SUPLEMENTI ZA PČELE",
      },
      protection: {
        en: "",
        sr: "ZAŠTITNA OPREMA",
      },
      products: {
        en: "",
        sr: "PČELINJI PROIZVODI",
      },
    },
  },
  assortment: {
    title: {
      en: "",
      sr: "NAŠ ASORTIMAN",
    },
    equipment: {
      title: {
        en: "",
        sr: "OPREMA <br/> ZA PČELARSTVO",
      },
      description: {
        en: "",
        sr: `Oprema za vrcanje, skladištenje i pakovanje meda, radni stolovi, sakupljači polena.`,
      },
    },
    tools: {
      title: {
        en: "",
        sr: "PRIBOR <br/> ZA PČELARSTVO",
      },
      description: {
        en: "",
        sr:
          "Pribor za ramove, žica i mreže, pčelarski noževi i viljuške, pribor za rad sa maticom.",
      },
    },
    medicine: {
      title: {
        en: "",
        sr: "LEKOVI <br/> ZA PČELE",
      },
      description: {
        en: "",
        sr:
          "Čitava paleta veoma efikasnih i proverenih lekova i preparata za prevenciju i lečenje pčela.",
      },
    },
    food: {
      title: {
        en: "",
        sr: "HRANA <br/> ZA PČELE",
      },
      description: {
        en: "",
        sr:
          "Za zdrava i snažna pčelinja društva u našoj ponudi su Apitom, Apitom-S, Apitom-P.",
      },
    },
    protection: {
      title: {
        en: "",
        sr: "ZAŠTITNA <br/> OPREMA",
      },
      description: {
        en: "",
        sr:
          "Pčelarska zaštitna odeća u vidu kombinezona, bluza, šešira i rukavica.",
      },
    },
    products: {
      title: {
        en: "",
        sr: "PČELINJI <br/> PROIZVODI",
      },
      description: {
        en: "",
        sr:
          "Api fito program, Apina kozmetika, nekoliko vrsta meda, matična mleč, propolis i polen.",
      },
    },
  },
  accomplishments: {
    awards: {
      en: "Awards",
      sr: "Awards",
    },
    clients: {
      en: "Clients",
      sr: "Clients",
    },
    team: {
      sr: "Team",
      en: "Team",
    },
    project: {
      sr: "Project",
      en: "Project",
    },
  },
  gallery: {
    title: {
      en: "Gallery",
      sr: "Galerija",
    },
    production: {
      en: "Production",
      sr: "Proizvodnja",
    },
    products: {
      en: "Products",
      sr: "Proizvodi",
    },
    workspace: {
      en: "Workspace",
      sr: "Poslovni prostor",
    },
    team: {
      en: "Our team",
      sr: "Naš tim",
    },
    all: {
      en: "All",
      sr: "Sve",
    },
  },
  footer: {
    input: {
      en: "",
      sr: "Unesite Vašu e-mail adresu!",
    },
    address: {
      line1: {
        en: "",
        sr: "D.O.O. Za spoljnu i unutrašnju trgovinu i proizvodnju",
      },
      line2: {
        en: "",
        sr: "22400 Ruma, Kraljevačka 46. Republika Srbija",
      },
      line3: {
        en: "",
        sr: "tel: +381 (0) 22 479 569, fax: +381 (0) 22 471 675",
      },
    },
    login: {
      en: "Login and registration",
      sr: "Prijava i registracija",
    },
    contactUs: {
      en: "Contact us",
      sr: "Kontaktirajte nas",
    },
  },
})
