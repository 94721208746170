import jwt_decode from "jwt-decode"

export function decodeToken(token: string) {
  if (!token) {
    return
  }
  const decoded: any = jwt_decode(token)
  console.log(decoded)
  return decoded
}
