import { Language } from "./languages"
import { observable, makeObservable, action } from "mobx"
import { getItem, setItem } from "../lib/localStorage"
import Cookies from "js-cookie"
import { decodeToken } from "../lib/jwt"

class Store {
  language: Language = (getItem("language") as Language) ?? "sr"
  token: string = Cookies.get("cEvrtT") || null
  initialLoad: boolean = false
  userInfo = null

  constructor() {
    makeObservable(this, {
      language: observable,
      token: observable,
      initialLoad: observable,
      changeLanguage: action,
      setToken: action,
      setInitialLoad: action,
    })
  }

  changeLanguage(lang: Language) {
    this.language = lang
    setItem("language", lang)
  }

  setInitialLoad() {
    this.initialLoad = true
  }

  setToken(token: string) {
    this.token = token
    const decoded = decodeToken(token)
    this.userInfo = decoded
  }
}

export const store = new Store()
